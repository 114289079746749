/* eslint-disable max-classes-per-file */
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import {
  catchError, filter, finalize, mapTo, switchMap, takeUntil, tap, take,
} from 'rxjs/operators';
import {
  BehaviorSubject, forkJoin, Observable, of, Subject, Subscription, throwError,
} from 'rxjs';
import { Store } from '@ngxs/store';
import { HttpErrorResponse } from '@angular/common/http';
import { DOCUMENT, Location } from '@angular/common';
import moment from 'moment';
import {
  MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { AuthError, EventMessage, EventType } from '@azure/msal-browser';
import { UserDtoModel } from '../api/models/dtos/user.dto.model';
import { UserService } from '../api/services/user.service';
import {
  ClearBaseData,
  MarkImpersonation,
  RefreshBaseData,
  UserSelectors,
  UserStateActions,
} from '../state';
import { BasicUser } from '../models/user.interface';
import { isEmpty } from '../helpers/utilities';
import { assertRequiredProperties } from '../api/utilities/api.utility';

export const ADMIN_ROLE_NAME = 'Administrator';
export const SUPER_ADMIN_ROLE_NAME = 'Superadmin';
export const USER_PLUS_ROLE_NAME = 'User+';

export interface AuthenticationUserInfo extends BasicUser {
  userId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  timezone: string;
  timezoneOffset: number;
  validFrom: string;
  validUntil: string;
}

export class AuthenticationUserInfoModel {
  constructor(
    readonly userId: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly fullName: string,
    readonly email: string,
    readonly timezone: string,
    readonly timezoneOffset: number,
    readonly validFrom: moment.Moment | undefined,
    readonly validUntil: moment.Moment | undefined,
    // Unused photoUrl
    readonly photoUrl?: string,
  ) {
    this.validFrom = (!isEmpty(validFrom) ? validFrom.clone() : undefined);
    this.validUntil = (!isEmpty(validUntil) ? validUntil.clone() : undefined);

    // this.fullName = `${this.firstName} ${this.lastName}`.trim();
  }

  static fromUser(user: AuthenticationUserInfo): AuthenticationUserInfoModel {
    assertRequiredProperties(user, [
      'userId',
      'firstName',
      'lastName',
      'fullName',
      'email',
      'timezone',
      'timezoneOffset',
    ]);

    return new AuthenticationUserInfoModel(
      user.userId,
      user.firstName,
      user.lastName,
      user.fullName,
      user.email,
      user.timezone,
      user.timezoneOffset,
      (!isEmpty(user.validFrom)
        ? moment(user.validFrom)
          .parseZone()
        : undefined
      ),
      (!isEmpty(user.validUntil)
        ? moment(user.validUntil)
          .parseZone()
        : undefined
      ),
    );
  }
}

export class AuthenticationStart {
  toString(): string {
    return 'AuthenticationStart[]';
  }
}

export class AuthenticationSuccess {
  readonly username: string;

  constructor(username: string) {
    this.username = username;
  }

  toString(): string {
    return `AuthenticationSuccess[username=${this.username}]`;
  }
}

export class AuthenticationError {
  readonly error: AccessControlError;

  constructor(error: AccessControlError) {
    this.error = error;
  }

  toString(): string {
    return `AuthenticationError[error=${this.error}`;
  }
}

export class AuthenticationEnd {
  readonly authenticated: boolean;

  constructor(authenticated: boolean) {
    this.authenticated = authenticated;
  }

  toString(): string {
    return `AuthenticationEnd[authenticated=${this.authenticated}]`;
  }
}

export class AuthorizationCheckStart {
  toString(): string {
    return 'AuthorizationCheckStart[]';
  }
}

export class AuthorizationCheckSuccess {
  readonly user: UserDtoModel;

  constructor(user: UserDtoModel) {
    this.user = user;
  }

  toString(): string {
    return `AuthorizationCheckSuccess[user=${this.user.userId}]`;
  }
}

export class AuthorizationCheckError {
  readonly error: AccessControlError;

  constructor(error: AccessControlError) {
    this.error = error;
  }

  toString(): string {
    return `AuthorizationCheckError[error=${this.error}]`;
  }
}

export class AuthorizationCheckEnd {
  readonly authorized: boolean;

  constructor(authorized: boolean) {
    this.authorized = authorized;
  }

  toString(): string {
    return `AuthorizationCheckEnd[authorized=${this.authorized}]`;
  }
}

/**
 * Represents an access control event, allowing you to track the lifecycle of the user access control.
 *
 * The sequence of access control events is:
 *
 * - {@link AuthenticationStart},
 * - {@link AuthenticationSuccess},
 * - {@link AuthenticationError},
 * - {@link AuthorizationCheckStart},
 * - {@link AuthorizationCheckSuccess},
 * - {@link AuthorizationCheckError},
 * - {@link AuthorizationCheckEnd},
 * - {@link AuthenticationEnd}
 */
export declare type AccessControlEvent =
  AuthenticationStart
  | AuthenticationSuccess
  | AuthenticationError
  | AuthenticationEnd
  | AuthorizationCheckStart
  | AuthorizationCheckSuccess
  | AuthorizationCheckError
  | AuthorizationCheckEnd;

export class AccessControlError {
  constructor(
    readonly name: string,
    readonly message: string,
    readonly code: number,
    readonly originalError?: Error | string | null,
  ) {
  }

  toString(): string {
    return `AccessControlError[name=${this.name}, message:${this.message}, code:${this.code}]`;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AccessControlService implements OnDestroy {
  canImpersonateUsers$ = this.store.select(UserSelectors.permission('canImpersonateUsers'));

  readonly accessControlPending$: Observable<boolean>;

  get accessControlPending(): boolean {
    return this.accessControlPendingSubject$.getValue();
  }

  get substituteUserId(): string | null { return this._substituteUserId; }

  readonly events$: Observable<AccessControlEvent>;

  private eventsSubject$: Subject<AccessControlEvent>;

  private _error: AccessControlError | null = null;

  private _substituteUserId: string | null = null;

  private _canLoginAsSubstituteUser: boolean = false;

  private timer: any;

  private accessControlPendingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  private destroyed$: Subject<void> = new Subject();

  private loggedIn: boolean = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(DOCUMENT) protected document: Document,
    private readonly location: Location,
    private readonly router: Router,
    private readonly store: Store,
    private readonly msalService: MsalService,
    private readonly userService: UserService,
    private readonly msalBroadcastService: MsalBroadcastService,
  ) {
    this.eventsSubject$ = new Subject();
    this.events$ = this.eventsSubject$.asObservable();

    this.accessControlPending$ = this.accessControlPendingSubject$.asObservable();
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  start(): void {
    if (this.accessControlPending) {
      throw new Error('Cannot start a new access control check while another one is still pending');
    }

    this.checkAccount();
    this.accessControlPendingSubject$.next(true);
    this._error = null;
    this.eventsSubject$.next(new AuthenticationStart());

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        take(1), // @TODO: investigate for a better solution
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.checkAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE
          || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.checkAccount();
        this.onAuthenticationFailure();
      });

    this.canImpersonateUsers$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((val) => {
        this._canLoginAsSubstituteUser = val;
      });

    if (this.authenticated) {
      this.onAuthenticationSuccess();
    } else {
      this.onAuthenticationFailure();
    }
  }

  get access(): boolean {
    return (this.authenticated && this.authorized);
  }

  get authenticated(): boolean {
    return this.loggedIn;
  }

  get authorized(): boolean {
    return (this.store.selectSnapshot(UserSelectors.user) != null);
  }

  get error(): AccessControlError | null {
    return this._error;
  }

  /**
   * Check the most recent or a given error for whether a new
   * login request is required, sending the user to the login
   * endpoint where he can enter his credentials.
   *
   * Use this method to check for errors like
   *
   *   login_required
   *   AADSTS50058: A silent sign-in request was sent but no user is signed in.
   *
   * @param error
   */
  isUserLoginRequired(error?: AccessControlError): boolean {
    let msalError;

    if (error == null) {
      msalError = this.error;
    } else {
      msalError = error.originalError;
    }

    if (msalError && typeof msalError !== 'string' && msalError.name === 'login_required') {
      return true;
    }

    return false;
  }

  isUserAuthorizationCurrentlyInvalid(error?: AccessControlError | null): boolean {
    const parsedError = error == null ? this.error : error;

    return !!(parsedError && parsedError.code === 403 && parsedError.message === 'NoValidAssignment');
  }

  isUserAuthorizationExpired(error?: AccessControlError | null): boolean {
    const parsedError = error == null ? this.error : error;

    return !!(parsedError && parsedError.code === 403 && parsedError.message === 'ValidUntil');
  }

  isUserAuthorizationNotYetValid(error?: AccessControlError | null): boolean {
    const parsedError = error == null ? this.error : error;

    return !!(parsedError && parsedError.code === 403 && parsedError.message === 'ValidFrom');
  }

  getUserInfoFromError(error?: AccessControlError | null): AuthenticationUserInfoModel | undefined {
    const parsedError = error == null ? this.error : error;

    if (
      parsedError
      && parsedError.originalError
      && (parsedError.originalError as any).user
    ) {
      return AuthenticationUserInfoModel.fromUser((parsedError.originalError as any).user);
    }

    return undefined;
  }

  login(): void {
    this.msalService.loginRedirect()
      .subscribe(
        () => {
          // not used
        },
        (e: unknown) => {
          const error = e as AuthError;

          if (error.errorCode !== 'interaction_in_progress' && error.errorCode !== 'redirect_in_iframe') {
            throw e;
          }

          if (window.sessionStorage) {
            for (let i = 0; i < sessionStorage.length; i += 1) {
              const storageKey = sessionStorage.key(i);

              if (storageKey?.includes('interaction.status')) {
                sessionStorage.removeItem(storageKey);
              }
            }

            this.login();
          }
        },
      );
  }

  /**
   * Logs out the current user and redirects to the logout page.
   */
  logout(): void {
    /* eslint-disable rxjs/no-ignored-observable */

    this.store.dispatch(new UserStateActions.ClearAllUserData());
    this.store.dispatch(new ClearBaseData());

    this.msalService.logout();

    /* eslint-enable rxjs/no-ignored-observable */
  }

  canLoginAsSubstituteUser(): boolean {
    return this._canLoginAsSubstituteUser;
  }

  isUserPlus(user: UserDtoModel | null = null): boolean {
    const finalUser = user || this.getCurrentUserOrOriginalUser();

    return !!(finalUser?.role?.name === USER_PLUS_ROLE_NAME);
  }

  isAdmin(user: UserDtoModel | null = null): boolean {
    const finalUser = user || this.getCurrentUserOrOriginalUser();

    return !!(finalUser?.role?.name === ADMIN_ROLE_NAME);
  }

  isSuperadmin(user: UserDtoModel | null = null): boolean {
    const finalUser = user || this.getCurrentUserOrOriginalUser();

    return !!(finalUser?.role?.name === SUPER_ADMIN_ROLE_NAME);
  }

  isAnyAdmin(user: UserDtoModel | null = null): boolean {
    const finalUser = user || this.getCurrentUserOrOriginalUser();

    return this.isAdmin(finalUser) || this.isSuperadmin(finalUser);
  }

  isUserSubstituted(): boolean {
    return !!this._substituteUserId;
  }

  // eslint-disable-next-line max-lines-per-function
  loginSubstituteUser$(userId: string): Observable<void> {
    return forkJoin([
      this.userService.getSubstituteUser$(userId),
      this.store.selectOnce(UserSelectors.user),
      this.store.selectOnce(UserSelectors.originalUser),
    ])
      .pipe(
        switchMap(([substituteUser, currentUser, originalUser]) => {
          // This might return null if the current user is lacking permission for SU.
          if (substituteUser == null) {
            return throwError(new Error(`Failed SU for user with ID ${userId}`));
          }

          if (currentUser == null && originalUser == null) {
            return of(null);
          }

          // In case we get a user for replacement, check whether we did do the SU before.

          // In case we did not do the SU before, use the current user.
          // TODO: Check after TypeScript upgrade. Remove " && currentUser != null".
          if (originalUser == null && currentUser != null) {
            // Backup the current user and set the substitute user as current user.
            return this.store.dispatch([
              new UserStateActions.SetOriginalUser(currentUser),
              new UserStateActions.SetLoginUser(substituteUser),
            ])
              .pipe(
                mapTo(substituteUser),
              );
          }

          // In case we already did a SU before, check whether the new SU is not ourselves.
          // TODO: Check after TypeScript upgrade. Remove "if (originalUser != null) {".
          if (originalUser != null) {
            if (originalUser.userId !== substituteUser.userId) {
              return this.store.dispatch([
                new UserStateActions.SetLoginUser(substituteUser),
              ])
                .pipe(
                  mapTo(substituteUser),
                );
            }

            // In case it's ourselves, do a "logout".
            return this.store.dispatch([
              new UserStateActions.SetLoginUser(originalUser),
              new UserStateActions.ClearOriginalUser(),
            ])
              .pipe(
                mapTo(null),
              );
          }

          return of(null);
        }),
        tap((user) => {
          if (user) {
            // Remember the user for which we did the SU.
            this._substituteUserId = user.userId;

            // eslint-disable-next-line rxjs/no-ignored-observable
            this.store.dispatch([
              new RefreshBaseData(user, true),
              new MarkImpersonation(true),
            ]);
          } else {
            // No user means logout or something else. In any case reset the `substituteUserId`.
            this._substituteUserId = null;
          }
        }),
        mapTo(undefined),
      );
  }

  logoutSubstituteUser$(): Observable<void> {
    // Try to get the original login user...
    return this.store.selectOnce(UserSelectors.originalUser)
      .pipe(
        switchMap((originalUser) => {
          if (originalUser) {
            // ...and restore it.
            return this.store.dispatch([
              new UserStateActions.SetLoginUser(originalUser),
              new UserStateActions.ClearOriginalUser(),
              new MarkImpersonation(false),
            ]).pipe(
              tap(() => {
                this._substituteUserId = null;
                // eslint-disable-next-line rxjs/no-ignored-observable
                this.store.dispatch(new RefreshBaseData(originalUser, true));
              }),
            );
          }

          return of(null);
        }),
        tap(() => {
          // Clear the user we did the SU for.
          this._substituteUserId = null;
        }),
        mapTo(undefined),
      );
  }

  /**
   * Create an absolute URL for a given route.
   * @see `Router.createUrlTree` for documentation of the input parameters.
   *
   * @param commands
   * @param navigationExtras
   */
  getAbsoluteUrl(commands: any[], navigationExtras?: NavigationExtras): string {
    const localUrl = this.router
      .createUrlTree(commands, navigationExtras)
      .toString();

    return `${this.document.location.origin}${this.location.prepareExternalUrl(localUrl)}`;
  }

  private getCurrentUserOrOriginalUser(): UserDtoModel | null {
    const ogUser = this.store.selectSnapshot(UserSelectors.originalUser);
    const user = this.store.selectSnapshot(UserSelectors.user);

    return user || ogUser;
  }

  private onAuthenticationSuccess(): void {
    this.eventsSubject$.next(new AuthenticationSuccess(''));
    this.eventsSubject$.next(new AuthorizationCheckStart());
    this.userService
      .getCurrentUser$()
      .pipe(
        switchMap((user) => this.store.dispatch(new UserStateActions.SetLoginUser(user))
          .pipe(
            switchMap(() => this.store.dispatch([
              new RefreshBaseData(user),
              new MarkImpersonation(false),
            ])),
            switchMap(() => of(user)),
          )),
        catchError((error: unknown) => this.store.dispatch(new UserStateActions.ClearLoginUser())
          .pipe(
            switchMap(() => this.store.dispatch(new ClearBaseData())),
            switchMap(() => throwError(error)),
          )),
      )
      .pipe(
        finalize(() => {
          this.eventsSubject$.next(new AuthorizationCheckEnd(this.authorized));
          this.eventsSubject$.next(new AuthenticationEnd(true));
          this.accessControlPendingSubject$.next(false);
        }),
      )
      .subscribe((user) => {
        this.eventsSubject$.next(new AuthorizationCheckSuccess(user));
      }, (error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          const errorObj: Error | string | null = error.error;
          let errorMessage;

          if (errorObj != null) {
            errorMessage = typeof errorObj !== 'string' && 'message' in errorObj ? errorObj.message : errorObj;
          } else {
            errorMessage = `${error.status} ${error.statusText}`;
          }

          this._error = new AccessControlError('API Error', errorMessage, error.status, errorObj);
        } else {
          this._error = new AccessControlError('Application Error', (Object.prototype.hasOwnProperty.call(error, 'message') ? (error as HttpErrorResponse).message : error as string), 500);
        }
        this.eventsSubject$.next(new AuthorizationCheckError(error as AccessControlError));
      });
  }

  private onAuthenticationFailure(): void {
    if (this.error) {
      this._error = new AccessControlError('ADAL Error', this.error.message, 401, this.error);
      this.eventsSubject$.next(new AuthenticationError(this._error));
    }
    this.eventsSubject$.next(new AuthenticationEnd(false));
    this.accessControlPendingSubject$.next(false);
  }

  private checkAccount(): void {
    this.loggedIn = this.msalService.instance.getAllAccounts().length > 0;
  }
}
